<template lang="">
  <div>
    <datatable
      class="mt-4"
      :data-of-table="getList"
      :table-columns="fields"
      :total="getTotal"
      :resource="`demandes`"
      :is-busy="getLoading"
      :buttons="[{icon:'EyeIcon', name:'detail',variant:'flat-success'}]"
      @detail="details"
    />
  </div>
</template>
<script>
import datatable from '@/@core/components/datatable/datatable.vue'

export default {
  components: {
    datatable,
  },
  data() {
    return {
      fields: [
        {
          key: 'id', label: 'Information numero', sortable: true,
        },
        'statut',
        {
          key: 'comment', label: 'Commentaire', sortable: true,
        },
        {
          key: 'createdAt', label: 'Date de demande',
        },
        {
          key: 'updatedAt', label: 'Date de modification de la demande',
        },
        'actions',
      ],
      header: 'Ajouter un utilisateur',
      id: '',
    }
  },
  computed: {
    getList() {
      return this.$store.getters['informations/list']
    },
    getTotal() {
      return this.$store.getters['informations/totalItems']
    },
    getLoading() {
      return this.$store.getters['informations/loading']
    },
  },
  mounted() {
    if (this.$store.getters['informations/list'].length === 0) {
      this.$store.dispatch('informations/list', { params: null })
    }
  },
  methods: {
    details(items) {
      this.$router.push({ name: 'information-details', params: { id: items.user.id } })
    },
    filter(params) {
      const body = params
      this.$store.dispatch('demandes/list', { params: body })
    },
  },
}
</script>
<style lang="">

</style>
